/* A separate container for the background image */
.background-image-container {
  position: relative;
  width: 100%;
  height: 120vh;
}

/* Background image with B&W effect and hover transition */
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../public/BG.png"); /* Correct path to image */
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  /* background: no-repeat; */
  filter: grayscale(100%);
  transition: filter 0.5s ease-in-out;
  z-index: -1; /* Ensure it stays behind other elements */
}

/* Remove grayscale on hover */
.background-image-container:hover .background-image {
  filter: grayscale(0%);
}

/* Media query for mobile background image */
@media (max-width: 600px) {
  .background-image {
    background-image: url("../../public/Mobile.png"); /* Path to mobile image */
  }

  .background-image-container {
    height: 104vh;
  }
}

/* Body styling */
body {
  background-color: #111111;
}
