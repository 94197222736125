body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Planeto";
  src: url("../public/assets/planeto-futuristic-font/Planeto.otf")
    format("opentype"); /* Use format 'opentype' for .otf files */
  font-weight: normal;
  font-style: normal;
}

/* Apply globally to the body or specific elements */
body {
  font-family: "Planeto", sans-serif;
}
